import { HttpParams } from '@angular/common/http';
import { PaginatedParam } from '../../paginated.param';

export class FindEvaluationsParam extends PaginatedParam {
  orderByField = 'dt_session';
  orderByAsc = false;

  infrastructureId: number;
  query?: string;

  toHttpParams(): HttpParams {
    let params = super.toHttpParams();

    if (this.infrastructureId != null) {
      params = params.set('_filter_infrastructure_id__exact', this.infrastructureId.toString());
    }

    if (this.query) {
      params = params.set('_filter_user_login', this.query);
    }

    return params;
  }
}
