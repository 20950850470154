import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '../../paginated-result.interface';
import { Customer } from '../customers/customer.interface';
import { AssociateCustomer } from './../associate-customer';

@Injectable({
  providedIn: 'root'
})
export class EngineersService {

  constructor(private http: HttpClient) {

  }

  getEngineerAssociateCustomerListByUsername(username: string): Observable<Customer[]> {
    const url = '/user/customers';
    const params = new HttpParams().set('user', username);

    return this.http.get<PaginatedResult<AssociateCustomer>>(url, { params })
      .pipe(map(value => value.items[0].customers));
  }

  addAssociateCustomerToEnginner(user: string, customer_id: number): Observable<Customer[]> {
    const url = '/user/customers';

    return this.http.post<PaginatedResult<AssociateCustomer>>('/user/customer', { user, customer_id })
      .pipe(map(value => value.items[0].customers));

    // .pipe(map((json: any) => json.items[0].customers.map(customer => customer)));

  }
  removeAssociateCustomerToEnginner(user: string, customer_id: number): Observable<any> {
    const url = '/user/customers';

    const params = this.http.getParams({ user, customer_id });

    return this.http.delete('/user/customer', { params });



  }

}
