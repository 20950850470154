import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { failureFactorTextToList } from '@app/@shared/libs/failure-factor/failure-factor-text';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '../../paginated-result.interface';
import { CreateEvaluationParam } from './create-evaluation.param';
import { Evaluation } from './evaluation';
import { CreateFailureModeParam } from './failure-modes/create-failure-mode.param';
import { CreateFailureStudioParam } from './failure-modes/create-failure-studio.param';
import { CreateFailureFactorParam } from './failure-modes/failure-factors/create-failure-factor.param';
import { FailureFactor } from './failure-modes/failure-factors/failure-factor';
import { UpdateFailureFactorParam } from './failure-modes/failure-factors/update-failure-factor.param';
import { FailureMode, FailureModeGrade } from './failure-modes/failure-mode';
import { FailureStudio } from './failure-modes/failure-studios';
import { UpdateFailureModeImageParam } from './failure-modes/update-failure-mode-image.param';
import { UpdateFailureModeParam } from './failure-modes/update-failure-mode.param';
import { FindEvaluationsParam } from './find-evaluation.param';
import { UpdateInfrastructureEvaluationParam } from './update-infrastructure-evaluation.param';

@Injectable({
  providedIn: 'root',
})
export class EvaluationsService {
  constructor(private http: HttpClient) {}

  createEvaluation(param: CreateEvaluationParam): Observable<Evaluation> {
    const url = '/infrastructure_evaluation';

    return this.http.post<Evaluation>(url, param);
  }

  getEvaluationById(evaluationId: number): Observable<Evaluation> {
    const url = '/infrastructure_evaluations';
    const params = this.http.getParams({ _filter_id__exact: evaluationId });

    return this.http.get<PaginatedResult<Evaluation>>(url, { params }).pipe(map((value) => value.items[0]));
  }

  updateInfrastructureEvaluation(param: UpdateInfrastructureEvaluationParam) {
    const url = '/infrastructure_evaluation';

    return this.http.patch(url, param);
  }

  findPaginatedEvaluations(param: FindEvaluationsParam): Observable<PaginatedResult<Evaluation>> {
    const url = '/infrastructure_evaluations';
    const params = param.toHttpParams();

    return this.http.get<PaginatedResult<Evaluation>>(url, { params });
  }

  deleteEvaluation(evaluationId: number): Observable<any> {
    const url = `/infrastructure_evaluation?id=${evaluationId}`;

    return this.http.delete(url);
  }

  createFailureMode(param: CreateFailureModeParam): Observable<FailureMode> {
    const url = '/infrastructure_failure_mode';
    const formData = param.toMultipartFormData();

    return this.http.post<FailureMode>(url, formData);
  }

  updateFailureMode(param: UpdateFailureModeParam) {
    const url = '/infrastructure_failure_mode';

    // const formData = param.toMultipartFormData();

    return this.http.patch<FailureMode>(url, param);
  }

  updateFailureModeImage(param: UpdateFailureModeImageParam): Observable<any> {
    const url = '/infrastructure_failure_mode/image';
    const formData = param.toMultipartFormData();
    const options = {} as any;

    return this.http.patch(url, formData, options);
  }

  findFailuresByEvaluationId(evaluation_id: number): Observable<FailureMode[]> {
    const url = '/infrastructure_failure_modes';
    const params = new HttpParams()
      .set('_filter_infrastructure_evaluation_id__exact', evaluation_id.toString())
      .set('_page_size', '0');

    return this.http.get<PaginatedResult<FailureMode>>(url, { params }).pipe(map((value) => value.items));
  }

  getInfrastructureFailureModesGrades(): Observable<FailureModeGrade[]> {
    const url = '/infrastructure_failure_modes/grades';
    const params = new HttpParams().set('_page_size', '0');

    return this.http.cache().get<PaginatedResult<FailureModeGrade>>(url, { params }).pipe(map((value) => value.items));
  }

  deleteFailureMode(failureModeId: number): Observable<any> {
    const url = `/infrastructure_failure_mode?id=${failureModeId}`;

    return this.http.delete(url);
  }

  getFailureStudiosByEvaluationId(evaluation_id: number): Observable<FailureStudio[]> {
    const url = '/infrastructure_proposed_studies';
    const params = new HttpParams()
      .set('_filter_infrastructure_evaluation_id__exact', evaluation_id.toString())
      .set('_page_size', '0');

    return this.http.get<PaginatedResult<FailureStudio>>(url, { params }).pipe(map((value) => value.items));
  }

  getFailureStudiosByCustomer(customer_login: string): Observable<FailureStudio[]> {
    const url = '/infrastructure_proposed_studies';
    const params = new HttpParams()
      .set('_filter_infrastructure_evaluation.infrastructure.customer.user_login__exact', customer_login)
      .set('_page_size', '0');

    return this.http.get<PaginatedResult<FailureStudio>>(url, { params }).pipe(map((value) => value.items));
  }

  createFailureStudio(param: CreateFailureStudioParam): Observable<FailureStudio> {
    const url = '/infrastructure_proposed_studio';

    return this.http.post<FailureStudio>(url, param);
  }

  deleteFailureStudio(failureStudioId: number): Observable<any> {
    const url = `/infrastructure_proposed_studio?id=${failureStudioId}`;

    return this.http.delete(url);
  }

  createFailureFactor(param: CreateFailureFactorParam): Observable<FailureFactor> {
    const url = '/infrastructure_failure_factor';

    return this.http.post<FailureFactor>(url, param);
  }

  createMultipleFailureFactorFromText(text: string, infrastructureFailureModesId: number): Observable<FailureFactor[]> {
    const paramList = failureFactorTextToList(text, infrastructureFailureModesId);
    const list = paramList.map((param, index) => this.createFailureFactor(param));

    return forkJoin(list);
  }

  updateFailureFactor(param: UpdateFailureFactorParam): Observable<FailureFactor> {
    // const url = '/infrastructure_failure_factor';

    // return this.http.post<FailureFactor>(url, param);
    return null;
  }

  removeFailureFactor(failureFactorId: number): Observable<any> {
    const url = '/infrastructure_failure_factor';
    const params = new HttpParams().set('id', failureFactorId.toString());

    return this.http.delete(url, { params });
  }
}
